import fm from 'format-message';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Seo from '@activebrands/core-web/components/seo-meta/structured-data/Seo';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SweetProtectionIcon from 'components/icons/SweetProtectionIcon';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '12px',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    transition: 'transform var(--transition-fast)',
    overflowY: 'scroll',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'var(--color-text-site-selector)',
});

const SiteSelectorLayout = ({ children, location }) => {
    const [css] = useStyletron();
    const { seo = {} } = useSiteSelectorQuery();
    const seoData = { content: { seo } };
    const year = new Date().getFullYear();

    return (
        <>
            <Seo data={seoData} location={location} />
            <Wrapper>
                <header
                    className={css({
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        padding: '16px',
                    })}
                >
                    <SweetProtectionIcon color="var(--color-top-logo-site-selector)" width="113px" />
                </header>
                {children}
                <footer
                    className={css({
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'var(--color-bg-footer-secondary)',
                        color: 'var(--color-text)',
                        padding: '16px',
                    })}
                >
                    <SweetProtectionIcon color="var(--fill-logo-footer-inverted)" width="113px" />
                    {fm('© { year } Sweet Protection', {
                        year,
                    })}
                </footer>
            </Wrapper>
        </>
    );
};

SiteSelectorLayout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object,
};

export default SiteSelectorLayout;
